import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { FaChevronDown } from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Big from 'big.js';
import classNames from 'classnames';
import Login from './Login';
import { set } from './actions';
import { CHAIN_NAMES, EXPLORER_BASE_URL, DEFAULT_CHAIN } from './config';

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const columns = [
  {
    id: 'base',
    label: 'BASE',
    action: 'sort',
  },
  { id: 'price', label: 'PRICE', action: 'sort' },
  { id: 'lastUpdate', label: 'LAST UPDATE', action: 'sort' },
  { id: 'taskId', label: 'TASK' },
  { id: 'update', label: 'SHOW MORE TOKENS', action: 'add' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes, onAddPaire } = this.props;
    return (
      <TableHead>
        <TableRow classes={{ root: classes.headerRow }}>
          {columns.map(
            column => (
              <TableCell
                key={column.id}
                align="center"
                padding="default"
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.action === 'sort' && (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                    IconComponent={FaChevronDown}
                  >
                    {column.label}
                  </TableSortLabel>
                )}
                {column.action === 'add' && (
                  <div className={classes.add} onClick={onAddPaire}>
                    {column.label}
                  </div>
                )}
                {!column.action && <span>{column.label}</span>}
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const styles = theme => ({
  root: {
    'grid-area': 'prices',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableWrapper: {
    width: '80hw',
  },
  table: {
    borderSpacing: '0 10px',
    borderCollapse: 'separate',
    '& td': {
      border: 'none',
    },
  },
  headerRow: {
    height: '1em',
    '& th': {
      border: 'none',
      fontSize: '1em',
      '& span': {
        '&:hover': {
          color: lighten(theme.palette.text.secondary, 0.3),
        },
        '&:focus': {
          color: lighten(theme.palette.text.secondary, 0.4),
        },
        color: lighten(theme.palette.text.secondary, 0.4),
      },
    },
  },
  row: {
    backgroundColor: theme.palette.background.paper,
    height: '5em',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  add: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    padding: '0.5em 1em',
    cursor: 'pointer',
  },
  title: {
    flex: '0 0 auto',
  },
  updateMe: {
    color: theme.palette.text.link,
    cursor: 'pointer',
  },
  updateMeDisabled: {
    color: theme.palette.text.disabled,
  },
  taskId: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  baseCell: {
    minWidth: '2.5em',
  },
  quoteCell: {
    minWidth: '20em',
  },
  updateCell: {
    minWidth: '10em',
  },
  taskCell: {
    width: '33em',
    maxWidth: '20vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  expander: {
    marginTop: '1em',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      '&:hover': {
        color: lighten(theme.palette.text.secondary, 0.3),
      },
      color: lighten(theme.palette.text.secondary, 0.4),
    },
  },
  divider: {
    flex: 1,
    margin: '0 1em',
    borderBottom: '1px solid',
    verticalAlign: 'middle',
    borderColor: lighten(theme.palette.text.secondary, 0.4),
  },
  loading: {
    lineHeight: '5em',
    textAlign: 'center',
  },
  progress: {
    margin: '0 1em',
  },
});

class EnhancedTable extends React.Component {
  render() {
    const rowsToDisplay = 6;
    const {
      classes,
      isLoggedIn,
      isLoading,
      chainId,
      listedBases,
      listedQuotes,
      pairs,
      pairsToDisplay,
      isExpanded,
      toggleExpand,
      order,
      orderBy,
      setSort,
      updatePaire,
      addPaire,
    } = this.props;

    const chainName = CHAIN_NAMES[chainId];
    const isSupportedChain = !!chainName;

    const createData = (base, quote, precision, price, lastUpdate, taskId) => {
      const baseAsset = listedBases.find(a => a.value === base);
      const quoteAsset = listedQuotes.find(a => a.value === quote);
      let displayPrice = '?';
      if (price !== undefined && lastUpdate !== 0) {
        const unitMultiplier = new Big(10).pow(-precision);
        const value = new Big(price).times(unitMultiplier);
        displayPrice = value.toString();
      }
      let displayUpdate = '';
      if (lastUpdate !== undefined) {
        displayUpdate =
          lastUpdate === 0 ? 'Never' : moment(lastUpdate).fromNow();
      }
      let displayTask = '';
      if (taskId) {
        if (
          taskId !==
          '0x0000000000000000000000000000000000000000000000000000000000000000'
        )
          displayTask = (
            <a
              href={`${EXPLORER_BASE_URL}${chainName}/task/${taskId}`}
              target="blank"
              className={classes.taskId}
            >
              {taskId}
            </a>
          );
      } else if (isLoading) {
        displayTask = (
          <div className={classes.loading}>
            LOADING
            <CircularProgress className={classes.progress} size={'1em'} />
          </div>
        );
      }
      return {
        id: `${base}-${quote}-${precision}`,
        base,
        baseLabel: baseAsset ? baseAsset.label : base.toUpperCase(),
        quote,
        quoteLabel: quoteAsset ? quoteAsset.label : quote.toUpperCase(),
        precision,
        price,
        displayPrice,
        lastUpdate,
        displayUpdate,
        taskId,
        displayTask,
      };
    };

    const data = pairsToDisplay
      .map(
        pair =>
          pairs.find(
            enhancedPair =>
              enhancedPair.base === pair.base &&
              enhancedPair.quote === pair.quote &&
              enhancedPair.precision === pair.precision,
          ) || pair,
      )
      .map(p => {
        return createData(
          p.base,
          p.quote,
          p.precision,
          p.value,
          p.date,
          p.taskId,
        );
      });

    const handleRequestSort = (event, property) => {
      const newOrderBy = property;
      let newOrder = 'desc';
      if (orderBy === newOrderBy && order === newOrder) {
        newOrder = 'asc';
      }
      setSort({ order: newOrder, orderBy: newOrderBy });
    };

    const updateHint = !isLoggedIn
      ? 'click to login'
      : !isSupportedChain
      ? `you current chain ${chainId} is not supported`
      : 'click to update this pair';

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table classes={{ root: classes.table }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onAddPaire={addPaire()}
              classes={classes}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(0, isExpanded ? data.length : rowsToDisplay)
                .map((n, index) => {
                  return (
                    <TableRow hover classes={{ root: classes.row }} key={n.id}>
                      <TableCell align="center" className={classes.baseCell}>
                        1 {n.baseLabel}
                      </TableCell>
                      <TableCell align="center" className={classes.quoteCell}>
                        {n.displayPrice} {n.quoteLabel}
                      </TableCell>
                      <TableCell align="center" className={classes.updateCell}>
                        {n.displayUpdate}
                      </TableCell>
                      <TableCell align="center" className={classes.taskCell}>
                        {n.displayTask}
                      </TableCell>
                      <TableCell align="center">
                        <Login>
                          <div
                            className={classNames(
                              classes.updateMe,
                              !isSupportedChain && classes.updateMeDisabled,
                              'hint--bottom',
                            )}
                            aria-label={updateHint}
                            onClick={
                              isLoggedIn && isSupportedChain
                                ? updatePaire({
                                    base: {
                                      value: n.base,
                                      label: n.baseLabel,
                                    },
                                    quote: {
                                      value: n.quote,
                                      label: n.quoteLabel,
                                    },
                                    precision: n.precision,
                                  })
                                : undefined
                            }
                          >
                            Trigger an update
                          </div>
                        </Login>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {data.length > rowsToDisplay && (
            <div className={classes.expander} onClick={toggleExpand}>
              <span className={classes.divider} />
              <span>{isExpanded ? ' COLLAPSE TABLE ' : ' EXPAND TABLE '}</span>
              <span className={classes.divider} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  chainId: state.chainId || DEFAULT_CHAIN,
  listedBases: state.listedBases,
  listedQuotes: state.listedQuotes,
  pairs: state.pairs,
  pairsToDisplay: state.pairsToDisplay,
  order: state.pairsSort.order,
  orderBy: state.pairsSort.orderBy,
  isLoggedIn: !!state.account,
  isExpanded: state.isTableExpended,
  isLoading: state.isLoading['GET_PAIRS'] || state.isLoading['GET_SINGLE_PAIR'],
});

const mapDispatchToProps = dispatch => ({
  toggleExpand: () => dispatch(set.toggleEpxandTable()),
  setSort: ({ order, orderBy }) => dispatch(set.pairsSort({ order, orderBy })),
  updatePaire: ({ base, quote, precision } = {}) => () =>
    dispatch(set.updateModal.open({ base, quote, precision })),
  addPaire: ({ base, quote, precision } = {}) => () =>
    dispatch(set.updateModal.openAdd()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(EnhancedTable));
