import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import BN from 'bn.js';
import transferForm from './transferForm';
import { DEFAULT_CHAIN, DEFAULT_PAIRS } from '../config';

const zeroBN = new BN(0);

function isLoading(state = {}, action) {
  const { type } = action;
  if (
    type.includes('SUCCESS') ||
    type.includes('FAILURE') ||
    type.includes('ABORT')
  ) {
    const scope = type
      .split('_SUCCESS')[0]
      .split('_FAILURE')[0]
      .split('_ABORT')[0];
    return Object.assign({}, state, {
      [scope]: false,
    });
  }
  if (type.includes('REQUEST')) {
    const scope = type.split('_REQUEST')[0];
    return Object.assign({}, state, {
      [scope]: true,
    });
  }
  return state;
}

function errors(state = [], action) {
  const { type, error } = action;

  if (type.includes('FAILURE')) {
    return [
      {
        timestamp: Date.now(),
        type,
        error,
      },
      ...state,
    ];
  }
  return state;
}

const defaultNotification = {
  message: '',
  position: 'tc',
  level: 'info',
  dismissible: 'button',
};

function notification(state = defaultNotification, action) {
  const { type, notification, error } = action;

  if (type.includes('FAILURE')) {
    return Object.assign({}, defaultNotification, {
      message: error,
      level: 'error',
    });
  } else if (type === 'NOTIFY') {
    return Object.assign({}, defaultNotification, {
      ...notification,
    });
  }
  return state;
}

function account(state = '', action) {
  switch (action.type) {
    case 'SET_ACCOUNT':
      const addr = action.account || '';
      return addr;
    default:
      return state;
  }
}

function chainId(state = DEFAULT_CHAIN, action) {
  switch (action.type) {
    case 'SET_CHAIN_ID':
      return action.chainId;
    default:
      return state;
  }
}

function rlcAccount(state = { stake: zeroBN, locked: zeroBN }, action) {
  switch (action.type) {
    case 'GET_RLC_ACCOUNT_SUCCESS':
      return action.account;
    default:
      return state;
  }
}

function rlcWallet(state = zeroBN, action) {
  switch (action.type) {
    case 'GET_RLC_WALLET_SUCCESS':
      return action.amount;
    default:
      return state;
  }
}

function listedBases(state = [], action) {
  switch (action.type) {
    case 'SET_LISTED_BASES':
      return action.bases;
    default:
      return state;
  }
}

function listedQuotes(state = [], action) {
  switch (action.type) {
    case 'SET_LISTED_QUOTES':
      return action.quotes;
    default:
      return state;
  }
}

function listedPairs(state = DEFAULT_PAIRS, action) {
  switch (action.type) {
    case 'SET_LISTED_PAIRS':
      return action.pairs;
    default:
      return state;
  }
}

function pairsToDisplay(state = DEFAULT_PAIRS, action) {
  switch (action.type) {
    case 'DISPLAY_NEW_PAIR':
      return [
        ...state.filter(
          e => !(e.base === action.pair.base && e.quote === action.pair.quote),
        ),
        action.pair,
      ];
    default:
      return state;
  }
}

function pairs(state = [], action) {
  switch (action.type) {
    case 'GET_PAIRS_SUCCESS':
      return action.pairs;
    case 'GET_SINGLE_PAIR_SUCCESS':
      return [
        ...state.filter(
          e => !(e.base === action.pair.base && e.quote === action.pair.quote),
        ),
        action.pair,
      ];
    default:
      return state;
  }
}

function isAccountModalOpen(state = false, action) {
  switch (action.type) {
    case 'TOGGLE_ACCOUNT_MODAL':
      return !state;
    default:
      return state;
  }
}

function isTableExpended(state = false, action) {
  switch (action.type) {
    case 'TOGGLE_EXPAND_TABLE':
      return !state;
    default:
      return state;
  }
}

function pairsSort(state = { order: 'desc', orderBy: 'lastUpdate' }, action) {
  switch (action.type) {
    case 'SET_PAIRS_SORT':
      return { order: action.order, orderBy: action.orderBy };
    default:
      return state;
  }
}

function updateModalDisplay(
  state = {
    isOpen: false,
    showAddButton: false,
  },
  action,
) {
  switch (action.type) {
    case 'OPEN_UPDATE_MODAL':
      return {
        isOpen: true,
        showAddButton: false,
      };
    case 'OPEN_ADD_OR_UPDATE_MODAL':
      return {
        isOpen: true,
        showAddButton: true,
      };
    case 'CLOSE_UPDATE_MODAL':
      return {
        isOpen: false,
        showAddButton: false,
      };
    default:
      return state;
  }
}

function modalActiveTab(state = 0, action) {
  switch (action.type) {
    case 'OPEN_UPDATE_MODAL':
    case 'OPEN_ADD_OR_UPDATE_MODAL':
      return 0;
    case 'SET_MODAL_ACTIVE_TAB':
      return action.value;
    case 'BUY_TASK_SUCCESS':
      return 1;
    default:
      return state;
  }
}

function selectedBase(state = null, action) {
  switch (action.type) {
    case 'OPEN_UPDATE_MODAL':
      return action.base;
    case 'OPEN_ADD_OR_UPDATE_MODAL':
      return null;
    case 'SELECT_BASE':
      return action.base;
    default:
      return state;
  }
}

function selectedQuote(state = null, action) {
  switch (action.type) {
    case 'OPEN_UPDATE_MODAL':
      return action.quote;
    case 'OPEN_ADD_OR_UPDATE_MODAL':
      return null;
    case 'SELECT_QUOTE':
      return action.quote;
    default:
      return state;
  }
}

function updateTaskId(state = '', action) {
  switch (action.type) {
    case 'SET_TASKID':
      return action.taskId;
    case 'BUY_TASK_SUCCESS':
      return action.taskId;
    default:
      return state;
  }
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    isLoading,
    notification,
    errors,
    account,
    chainId,
    rlcAccount,
    rlcWallet,
    isAccountModalOpen,
    listedBases,
    listedQuotes,
    listedPairs,
    pairsToDisplay,
    pairs,
    pairsSort,
    isTableExpended,
    updateModalDisplay,
    modalActiveTab,
    selectedBase,
    selectedQuote,
    updateTaskId,
    transferForm,
  });
