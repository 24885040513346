import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import ethereumLogo from './images/ethereum_logo.svg';

const styles = theme => ({
  root: {
    'grid-area': 'title',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  logoBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '1em',
    width: '100%',
  },
  logo: {
    height: '4em',
    width: 'auto',
    marginRight: '2em',
  },
  title: {
    fontSize: '2em',
    fontWeight: 'bold',
    margin: '0px',
    color: theme.palette.text.primary,
  },
});

const Title = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.logoBox}>
      <img className={classes.logo} src={ethereumLogo} alt="Ethereum logo" />
      <div className={classes.title}>Ethereum Price Feed</div>
    </div>
  </div>
);

export default withTheme()(withStyles(styles)(Title));
