import Debug from 'debug';
import { Contract, providers, utils as ethersUtils } from 'ethers';
import BN from 'bn.js';
import { utils } from 'iexec';
import fetch from 'cross-fetch';
import {
  all,
  takeLatest,
  takeEvery,
  put,
  select,
  race,
  call,
  take,
} from 'redux-saga/effects';
import {
  notify,
  set,
  getPairsAsync,
  getSinglePairAsync,
  getRLCWalletAsync,
  getRLCAccountAsync,
  depositRLCAsync,
  withdrawRLCAsync,
  buyIExecTaskAsync,
  watchTaskAsync,
  updateOracleAsync,
} from './actions';
import { getIExec, getEthProvider, getReadOnlyEthProvider } from './dappApi';
import {
  ORACLE_ADDRESSES,
  APP_ADDRESSES,
  DATASET_ADDRESSES,
  DEFAULT_CHAIN,
  CHAIN_NAMES,
} from './config';
import dOracleABI from './abi/iexec-doracle-abi.json';

const debug = Debug('sagas');

const sleep = ms => new Promise(res => setTimeout(res, ms));

export function* getSinglePair(action) {
  try {
    debug('getSinglePair()', action);
    const pairToGet = action.pair;
    const connectedChainId = yield select(state => state.chainId) ||
      DEFAULT_CHAIN;
    const chainId = !CHAIN_NAMES[connectedChainId]
      ? DEFAULT_CHAIN
      : connectedChainId;
    const dOracleAddress = ORACLE_ADDRESSES[chainId];
    if (!dOracleAddress) throw Error('No DOracle deployed on your chain');
    const ethProvider = getReadOnlyEthProvider(chainId);
    const dOracleContract = new Contract(
      dOracleAddress,
      dOracleABI,
      ethProvider,
    );
    const getPaireValues = async p =>
      Object.assign({}, p, {
        rpc: await dOracleContract.values(
          ethersUtils.solidityKeccak256(
            ['string'],
            [`${p.base}-${p.quote}-${p.precision}`],
          ),
        ),
      });
    const pairRPC = yield getPaireValues(pairToGet);
    const pair = {
      base: pairRPC.base,
      quote: pairRPC.quote,
      precision: pairRPC.precision,
      date: pairRPC.rpc.date ? parseInt(pairRPC.rpc.date.toString(), 10) : 0,
      value: pairRPC.rpc.value ? pairRPC.rpc.value.toString() : '',
      taskId: pairRPC.rpc.oracleCallID || '',
    };
    yield put(getSinglePairAsync.success(pair));
  } catch (error) {
    debug('getSinglePair()', error);
    yield put(getSinglePairAsync.failure('failed to get pair from DOracle'));
  }
}

export function* getPairs() {
  try {
    const connectedChainId = yield select(state => state.chainId) ||
      DEFAULT_CHAIN;
    const chainId = !CHAIN_NAMES[connectedChainId]
      ? DEFAULT_CHAIN
      : connectedChainId;
    const pairsToDisplay = yield select(state => state.pairsToDisplay);
    const dOracleAddress = ORACLE_ADDRESSES[chainId];
    if (!dOracleAddress) throw Error('No DOracle deployed on your chain');
    const provider = getReadOnlyEthProvider(chainId);
    const dOracleContract = new Contract(dOracleAddress, dOracleABI, provider);
    const getPaireValues = async p =>
      Object.assign({}, p, {
        rpc: await dOracleContract.values(
          ethersUtils.solidityKeccak256(
            ['string'],
            [`${p.base}-${p.quote}-${p.precision}`],
          ),
        ),
      });
    const pairsRPC = yield Promise.all(
      pairsToDisplay.map(p => getPaireValues(p)),
    );
    const pairs = pairsRPC.map(pairRPC => ({
      base: pairRPC.base,
      quote: pairRPC.quote,
      precision: pairRPC.precision,
      date: pairRPC.rpc.date ? parseInt(pairRPC.rpc.date.toString(), 10) : 0,
      value: pairRPC.rpc.value ? pairRPC.rpc.value.toString() : '',
      taskId: pairRPC.rpc.oracleCallID || '',
    }));
    yield put(getPairsAsync.success(pairs));
  } catch (error) {
    debug('getPairs()', error);
    yield put(getPairsAsync.failure('failed to get the pairs from DOracle'));
  }
}

export function* getRLCWallet() {
  try {
    const address = yield select(state => state.account);
    const chainId = yield select(state => state.chainId);
    debug('getRLCWallet()', chainId, 'for account', address);
    if (!address || !chainId || !CHAIN_NAMES[chainId]) {
      yield put(getRLCWalletAsync.success(new BN(0)));
      return;
    }
    const iexec = getIExec(chainId);
    const { nRLC } = yield iexec.wallet.checkBalances(address);
    debug('nRLC', nRLC);
    yield put(getRLCWalletAsync.success(nRLC));
  } catch (error) {
    debug('getRLCWallet()', error);
    yield put(getRLCWalletAsync.failure('failed to get RLC balance'));
  }
}

function* getRLCAccount() {
  try {
    const address = yield select(state => state.account);
    const chainId = yield select(state => state.chainId);
    debug('getRLCAccount()', chainId, 'for account', address);
    if (!address || !chainId || !CHAIN_NAMES[chainId]) {
      yield put(
        getRLCAccountAsync.success({ stake: new BN(0), locked: new BN(0) }),
      );
      return;
    }
    const iexec = getIExec(chainId);
    const balance = yield iexec.account.checkBalance(address);
    debug('balance', balance);
    yield put(getRLCAccountAsync.success(balance));
  } catch (error) {
    debug('getRLCAccount()', error);
    yield put(getRLCAccountAsync.failure(error.message));
  }
}

export function* depositRLC(action) {
  try {
    const { amount } = action;
    const address = yield select(state => state.account);
    const chainId = yield select(state => state.chainId);
    debug('depositRLC()', chainId, 'for account', address);
    const iexec = getIExec(chainId);
    yield put(
      notify({
        message:
          'Open Metamask to confirm the allowance transaction and the deposit transaction',
      }),
    );
    yield iexec.account.deposit(amount);
    yield put(
      notify({
        message: `✓ Successfully deposited ${amount} nRLC to your iExec account`,
        level: 'success',
      }),
    );
    yield put(getRLCWalletAsync.request());
    yield put(getRLCAccountAsync.request());
    yield put(depositRLCAsync.success());
  } catch (error) {
    debug('depositRLC()', error);
    yield put(depositRLCAsync.failure('failed to deposit RLC to your account'));
  }
}

export function* withdrawRLC(action) {
  try {
    const { amount } = action;
    const address = yield select(state => state.account);
    const chainId = yield select(state => state.chainId);
    debug('withdrawRLC()', chainId, 'for account', address);
    const iexec = getIExec(chainId);
    yield put(
      notify({
        message: 'Open Metamask to confirm the withdraw transaction',
      }),
    );
    yield iexec.account.withdraw(amount);
    yield put(
      notify({
        message: `✓ Successfully withdrawed ${amount} nRLC from your iExec account`,
        level: 'success',
      }),
    );
    yield put(getRLCWalletAsync.request());
    yield put(getRLCAccountAsync.request());
    yield put(withdrawRLCAsync.success());
  } catch (error) {
    debug('withdrawRLC()', error);
    yield put(
      withdrawRLCAsync.failure('failed to withdraw RLC from your account'),
    );
  }
}

function* buyIExecTask(action) {
  try {
    debug('buyIExecTask()', action);
    const { base, quote, precision } = action;
    const address = yield select(state => state.account);
    const chainId = yield select(state => state.chainId);
    const { stake } = yield select(state => state.rlcAccount);
    const iexec = getIExec(chainId);

    const appAddress = APP_ADDRESSES[chainId];
    if (!appAddress)
      throw new Error('The application is not deployed on your current chain');

    const datasetAddress = DATASET_ADDRESSES[chainId];
    if (!datasetAddress)
      throw new Error('The dataset is not deployed on your current chain');

    const getWorkerpoolOrder = async cat => {
      const {
        workerpoolOrders,
      } = await iexec.orderbook.fetchWorkerpoolOrderbook(cat, {
        minTag: ['tee'],
      });
      return workerpoolOrders[0] && workerpoolOrders[0].order;
    };

    let category = 0;
    let workerpoolOrder;
    while (category < 5 && !workerpoolOrder) {
      workerpoolOrder = yield getWorkerpoolOrder(category.toString());
      if (!workerpoolOrder) category = category + 1;
    }
    if (!workerpoolOrder)
      throw Error(
        `No workerpoolOrder published on categories 0 to ${category} on chain ${chainId}`,
      );
    const { appOrders } = yield iexec.orderbook.fetchAppOrderbook(appAddress);
    const appOrder = appOrders[0] && appOrders[0].order;
    if (!appOrder)
      throw Error(
        `No apporder published for ${appAddress} on chain ${chainId}`,
      );

    const {
      datasetOrders,
    } = yield iexec.orderbook.fetchDatasetOrderbook(datasetAddress, {
      app: appAddress,
    });
    const datasetOrder = datasetOrders[0] && datasetOrders[0].order;
    if (!datasetOrder)
      throw Error(
        `No datasetorder published for ${appAddress} on chain ${chainId}`,
      );

    const totalCost = new BN(appOrder.appprice).add(
      new BN(workerpoolOrder.workerpoolprice),
    );
    if (totalCost.gt(stake))
      throw Error(
        `Not enough RLC on your account, execution cost is ${totalCost} nRLC`,
      );

    yield put(
      notify({
        message: '1/2 Open MetaMask to sign your requestorder',
      }),
    );
    const signedRequestOrder = yield iexec.order
      .createRequestorder({
        app: appOrder.app,
        appmaxprice: appOrder.appprice,
        dataset: datasetAddress,
        datasetmaxprice: datasetOrder.datasetprice,
        workerpool: workerpoolOrder.workerpool,
        workerpoolmaxprice: workerpoolOrder.workerpoolprice,
        volume: '1',
        category,
        trust: '0',
        requester: address,
        beneficiary: utils.NULL_ADDRESS,
        callback: ORACLE_ADDRESSES[chainId],
        params: `python3 /app/oracle.py ${base} ${quote} ${precision}`,
      })
      .then(order => iexec.order.signRequestorder(order));

    if (!signedRequestOrder.sign) throw Error('Requestorder not signed');

    yield put(
      notify({
        message: '2/2 Open MetaMask to make the deal',
      }),
    );
    const { dealid } = yield iexec.order.matchOrders({
      apporder: appOrder,
      datasetorder: datasetOrder,
      workerpoolorder: workerpoolOrder,
      requestorder: signedRequestOrder,
    });
    const taskId = yield iexec.deal.computeTaskId(dealid, 0);
    yield put(
      notify({
        level: 'success',
        message: `Deal concluded`,
      }),
    );
    yield put(watchTaskAsync.request(taskId));
    yield put(getRLCAccountAsync.request());
    yield put(buyIExecTaskAsync.success(taskId));
  } catch (error) {
    debug('buyIExecTask()', error);
    yield put(buyIExecTaskAsync.failure(error.message));
  }
}

function* watchTask(action) {
  try {
    debug('watchTask()', action);
    const { taskId } = action;
    const chainId = yield select(state => state.chainId);
    const iexec = getIExec(chainId);
    const fetchTask = async () => {
      await sleep(10000);
      return iexec.task.show(taskId);
    };
    let task, stop;
    while (!stop) {
      try {
        const { res, abort } = yield race({
          res: call(fetchTask),
          abort: take('CLOSE_UPDATE_MODAL'),
        });
        if (res) task = res;
        if (abort || task.status === 3 || task.status === 4) stop = true;
      } catch (error) {
        debug('error', error);
      }
    }
    if (task.status === 3) yield put(watchTaskAsync.success());
    else if (task.status === 4)
      yield put(watchTaskAsync.failure('Task status is failed'));
    else yield put(watchTaskAsync.abort());
  } catch (error) {
    debug('watchTask()', error);
    yield put(watchTaskAsync.failure(error.message));
  }
}

function* updateOracle(action) {
  try {
    debug('updateOracle()', action);
    const { taskId } = action;
    const chainId = yield select(state => state.chainId);
    const dOracleAddress = ORACLE_ADDRESSES[chainId];
    if (!dOracleAddress) throw Error('No DOracle deployed on your chain');
    const ethSigner = new providers.Web3Provider(
      getEthProvider(chainId),
    ).getSigner();
    const dOracleContract = new Contract(dOracleAddress, dOracleABI, ethSigner);
    yield put(
      notify({
        message: 'Open MetaMask to send update transaction',
      }),
    );
    const tx = yield dOracleContract.processResult(taskId);
    yield tx.wait();
    yield sleep(5000);
    yield put(
      notify({
        level: 'success',
        message: `Oracle successfully updated`,
      }),
    );
    yield put(updateOracleAsync.success());
    yield put(set.updateModal.close());
    yield put(getPairsAsync.request());
  } catch (error) {
    debug('updateOracle()', error);
    yield put(updateOracleAsync.failure(error.message));
  }
}

function* getListedPairs() {
  try {
    const instruments = yield fetch(
      'https://reference-data-api.kaiko.io/v1/instruments',
    ).then(res => {
      if (res.status !== 200)
        throw Error(`Status ${res.status} ${res.statusText}`);
      return res.json();
    });
    const listedPairs = instruments.data
      .filter(
        ({ trade_start_timestamp, trade_end_timestamp }) =>
          !!trade_start_timestamp && !trade_end_timestamp,
      )
      .map(({ base_asset, quote_asset }) => ({
        base: base_asset,
        quote: quote_asset,
        precision: 9,
      }))
      .sort((a, b) => {
        if (a.base > b.base) return 1;
        if (a.base < b.base) return -1;
        if (a.quote > b.quote) return 1;
        if (a.quote < b.quote) return -1;
        return 0;
      })
      //remove duplicated
      .filter((item, pos, array) => {
        return (
          !pos ||
          `${item.base}/${item.quote}` !==
            `${array[pos - 1].base}/${array[pos - 1].quote}`
        );
      });
    debug('listedPairs', listedPairs.length);
    const assets = yield fetch(
      'https://reference-data-api.kaiko.io/v1/assets',
    ).then(res => {
      if (res.status !== 200)
        throw Error(`Status ${res.status} ${res.statusText}`);
      return res.json();
    });
    const listedAssets = assets.data
      .filter(
        ({ name }) =>
          name.indexOf(' Short') === -1 && name.indexOf(' Long') === -1,
      )
      .map(({ code, name }) => ({
        value: code,
        label: code.toUpperCase(),
        name,
      }))
      .sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
    debug('listedAssets', listedAssets.length);
    const bases = listedAssets.filter(
      ({ value }) => !!listedPairs.find(pair => pair.base === value),
    );
    debug('bases', bases.length);
    const quotes = listedAssets.filter(
      ({ value }) => !!listedPairs.find(pair => pair.quote === value),
    );
    debug('quotes', quotes.length);
    yield put(set.listedPairs(listedPairs));
    yield put(set.listedBases(bases));
    yield put(set.listedQuotes(quotes));
  } catch (error) {
    debug('getListedPairs', error);
    yield put(
      notify({
        level: 'error',
        message: `Failed to get listed pairs from Kaiko API`,
      }),
    );
  }
}

function* getListedAssets() {
  try {
    const { data } = yield fetch(
      'https://reference-data-api.kaiko.io/v1/assets',
    ).then(res => {
      if (res.status !== 200)
        throw Error(`Status ${res.status} ${res.statusText}`);
      return res.json();
    });
    const listedAssets = data
      .filter(({ asset_class }) => asset_class === 'cryptocurrency')
      .map(({ code, name }) => ({ code, name }))
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    debug('listedAssets', listedAssets.length);
    yield put(set.listedAssets(listedAssets));
  } catch (error) {
    debug('getListedAssets', error);
    yield put(
      notify({
        level: 'error',
        message: `Failed to get listed assets from Kaiko API`,
      }),
    );
  }
}

function* refreshPage(action) {
  const chainId = yield select(state => state.chainId);
  yield put(getPairsAsync.request());
  yield put(getRLCWalletAsync.request());
  yield put(getRLCAccountAsync.request());
  if (chainId && !CHAIN_NAMES[chainId])
    yield put(
      notify({
        message: `Your current chain ${chainId} is not supported, displaying ${CHAIN_NAMES[DEFAULT_CHAIN]}`,
        level: 'error',
      }),
    );
}

function* refreshUser(action) {
  yield put(getRLCWalletAsync.request());
  yield put(getRLCAccountAsync.request());
}

// SAGAS WATCHERS
export function* watch() {
  yield takeLatest('STARTUP', getListedPairs);
  yield takeLatest('STARTUP', getListedAssets);
  yield takeLatest('STARTUP', refreshPage);
  yield takeLatest('SET_CHAIN_ID', refreshPage);
  yield takeLatest('SET_ACCOUNT', refreshUser);
  yield takeLatest('GET_PAIRS_REQUEST', getPairs);
  yield takeEvery('GET_SINGLE_PAIR_REQUEST', getSinglePair);
  yield takeLatest('GET_RLC_ACCOUNT_REQUEST', getRLCAccount);
  yield takeLatest('GET_RLC_WALLET_REQUEST', getRLCWallet);
  yield takeLatest('DEPOSIT_RLC_REQUEST', depositRLC);
  yield takeLatest('WITHDRAW_RLC_REQUEST', withdrawRLC);
  yield takeLatest('BUY_TASK_REQUEST', buyIExecTask);
  yield takeLatest('UPDATE_ORACLE_REQUEST', updateOracle);
  yield takeLatest('WATCH_TASK_REQUEST', watchTask);
}

export default function* rootSaga() {
  yield all([watch()]);
}
