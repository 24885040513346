import React from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Login from './Login';
import { set } from './actions';
import rlcLogo from './images/rlc.svg';

const debug = Debug('AccountButton');
const LOGOUT_MSG = 'click to manage your account';
const LOGIN_MSG = 'unlock MetaMask to login';

const styles = theme => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
  },
  accountBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 20px',
  },
  accountTitle: {
    fontSize: '1.4rem',
  },
  rlc: {
    height: 'auto',
    width: '2em',
    margin: '0.4em',
    color: theme.palette.primary.dark,
  },
});

const AccountButton = ({
  classes,
  isLoggedIn,
  children,
  toggleAccountModal,
}) => {
  const hint = isLoggedIn ? LOGOUT_MSG : LOGIN_MSG;

  return (
    <Login>
      <div
        aria-label={hint}
        className={classNames(classes.root, 'hint--bottom-left')}
      >
        {isLoggedIn ? (
          <div onClick={toggleAccountModal} className={classes.accountBox}>
            <img className={classes.rlc} src={rlcLogo} alt="iExec RLC logo" />
            <div className={classes.accountTitle}>Account</div>
          </div>
        ) : (
          <div className={classes.accountBox}>
            <div className={classes.accountTitle}>Login</div>
          </div>
        )}
      </div>
    </Login>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: !!state.account,
});

const mapDispatchToProps = dispatch => ({
  toggleAccountModal: () => dispatch(set.toggleAccountModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AccountButton));
