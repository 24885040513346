import React from 'react';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import {
  CHAIN_NAMES,
  ORACLE_ADDRESSES,
  DEFAULT_CHAIN,
  BLOCKCHAIN_EXPLORERS,
} from './config';

const styles = theme => ({
  root: {
    'grid-area': 'intro',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    color: theme.palette.text.primary,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '275px',
    maxWidth: '900px',
    textAlign: 'justify',
    textJustify: 'inter-word',
    '& p': {
      margin: '0px',
      marginBottom: '1em',
      fontSize: '1em',
      lineHeight: '1.6em',
    },
    '& a': {
      color: theme.palette.text.link,
      textDecoration: 'none',
    },
  },
});

const ContactCard = ({ classes, displayChainId }) => {
  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <p>
          We are proud to offer an innovative price feed that combines the high
          level of security brought in by TEE technology (Trusted Execution
          Environment), the most complete choice of crypto pairs provided by{' '}
          <a href="https://www.kaiko.com">Kaiko</a> (hundreds of them), and the
          flexibility of the iExec platform (any kind of logic can be
          implemented to override the default behavior). We encourage you to
          give it a try, by triggering a price update for your favorite crypto
          using the dashboard below! Also, click{' '}
          <a
            href={`${BLOCKCHAIN_EXPLORERS[displayChainId]}/address/${ORACLE_ADDRESSES[displayChainId]}`}
          >
            here
          </a>{' '}
          to open it in Etherscan.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  displayChainId: CHAIN_NAMES[state.chainId] ? state.chainId : DEFAULT_CHAIN,
});

export default connect(
  mapStateToProps,
  undefined,
)(withTheme()(withStyles(styles)(ContactCard)));
