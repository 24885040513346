import Debug from 'debug';
import { IExec } from 'iexec';
import { HUB_ADDRESSES, DEFAULT_PROVIDERS } from './config';
import { getDefaultProvider } from 'ethers';

const debug = Debug('iexec');

let ethProvider;

const getUserAccount = () =>
  ethProvider.isMetaMask && ethProvider.selectedAddress
    ? ethProvider.selectedAddress
    : ethProvider.send({ method: 'eth_accounts', params: [] }).result[0];

const getNetworkVersion = () =>
  ethProvider.isMetaMask && ethProvider.networkVersion
    ? ethProvider.networkVersion
    : ethProvider.send({ method: 'net_version', params: [] }).result;

const sleep = ms => new Promise(res => setTimeout(res, ms));

const tryEnableProvider = async () => {
  if (window.ethereum) {
    debug('window.ethereum', window.ethereum);
    ethProvider = window.ethereum;
    await ethProvider.enable();
    debug('window.ethereum.enable()');
    return;
  }
  throw Error('Please install MetaMask');
};

export const init = async ({ setAccount, setChainId, initCallback }) => {
  let connected = false;
  await tryEnableProvider();
  connected = true;
  let currChainId = parseInt(getNetworkVersion(), 10);
  debug('currChainId', currChainId);
  if (currChainId) setChainId(currChainId);
  const checkChainId = async () => {
    const newChainId = await parseInt(getNetworkVersion(), 10);
    if (newChainId !== currChainId) {
      setChainId(newChainId);
      currChainId = newChainId;
    }
    if (connected) {
      await sleep(200);
      checkChainId();
    }
    if (!connected) {
      setChainId(null);
    }
  };
  checkChainId();
  let currAccount = getUserAccount();
  debug('currAccount', currAccount);
  if (currAccount) setAccount(currAccount);
  const checkAccount = async () => {
    const accountAddress = getUserAccount();
    const newAccount = accountAddress ? accountAddress : '';
    if (newAccount !== currAccount) {
      debug('Metamask change account', newAccount);
      setAccount(newAccount);
      currAccount = newAccount;
    }
    if (newAccount === '') connected = false;
    if (connected) {
      await sleep(200);
      checkAccount();
    }
  };
  checkAccount();
  if (initCallback && typeof initCallback === 'function') initCallback();
};

export const getIExec = chainId => {
  return new IExec({
    ethProvider,
    chainId,
    hubAddress: HUB_ADDRESSES[chainId],
  });
};

export const getEthProvider = chainId => ethProvider;

export const getReadOnlyEthProvider = chainId => {
  const defaultProvider = DEFAULT_PROVIDERS[chainId];
  if (!defaultProvider) throw Error(`Unsupported chain ${chainId}`);
  return getDefaultProvider(defaultProvider);
};
