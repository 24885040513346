import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import kaikoLogo from './images/kaiko-logo.png';
import iexecLogo from './images/iexec-logo.svg';

const styles = theme => ({
  root: {
    'grid-area': 'footer',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: theme.palette.text.primary,
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
  },
  logo: {
    height: '2em',
    width: 'auto',
    padding: '0.2em',
    margin: 'auto 1em',
  },
  iexecLogo: {
    height: '2.8em',
  },
  kaikoLogo: {
    height: '2em',
    marginTop: '0.4em',
    marginBottom: '0.4em',
  },
  title: {
    fontSize: '2em',
    fontWeight: 'bold',
    margin: '0px',
  },
});

const Footer = ({ classes }) => (
  <div className={classes.root}>
    <p>
      <b>Powered by</b>
    </p>
    <div className={classes.logoBox}>
      <a href="https://www.kaiko.com">
        <img
          className={classNames(classes.logo, classes.kaikoLogo)}
          src={kaikoLogo}
          alt="kaiko"
        />
      </a>
      <a href="https://iex.ec">
        <img
          className={classNames(classes.logo, classes.iexecLogo)}
          src={iexecLogo}
          alt="iExec"
        />
      </a>
    </div>
  </div>
);

export default withTheme()(withStyles(styles)(Footer));
