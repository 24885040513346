import React, { Fragment } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { set, notify } from './actions';
import { init } from './dappApi';

const debug = Debug('Login');

const Login = ({
  classes,
  isLoggedIn,
  children,
  setChainId,
  setAccount,
  initCallback,
  notify,
}) => {
  return (
    <Fragment>
      {isLoggedIn ? (
        <div>{children}</div>
      ) : (
        <div
          onClick={async () => {
            try {
              await init({ setAccount, setChainId, initCallback });
            } catch (e) {
              notify({ message: e.message, level: 'error' });
            }
          }}
        >
          {children}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: !!state.account,
});

const mapDispatchToProps = dispatch => ({
  setAccount: account => dispatch(set.account(account)),
  setChainId: chainId => dispatch(set.chainId(chainId)),
  notify: message => dispatch(notify(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
