export const notify = notification => ({ type: 'NOTIFY', notification });

export const set = {
  account: account => ({ type: 'SET_ACCOUNT', account }),
  chainId: chainId => ({ type: 'SET_CHAIN_ID', chainId }),
  toggleAccountModal: () => ({ type: 'TOGGLE_ACCOUNT_MODAL' }),
  toggleEpxandTable: () => ({ type: 'TOGGLE_EXPAND_TABLE' }),
  pairsSort: ({ order, orderBy }) => ({
    type: 'SET_PAIRS_SORT',
    order,
    orderBy,
  }),
  updateModal: {
    open: ({ base, quote, precision }) => ({
      type: 'OPEN_UPDATE_MODAL',
      base,
      quote,
      precision,
    }),
    openAdd: () => ({
      type: 'OPEN_ADD_OR_UPDATE_MODAL',
    }),
    close: () => ({ type: 'CLOSE_UPDATE_MODAL' }),
  },
  modalActiveTab: value => ({ type: 'SET_MODAL_ACTIVE_TAB', value }),
  listedPairs: pairs => ({ type: 'SET_LISTED_PAIRS', pairs }),
  listedAssets: assets => ({ type: 'SET_LISTED_ASSETS', assets }),
  listedBases: bases => ({ type: 'SET_LISTED_BASES', bases }),
  listedQuotes: quotes => ({ type: 'SET_LISTED_QUOTES', quotes }),
  base: asset => ({
    type: 'SELECT_BASE',
    base: asset,
  }),
  quote: asset => ({
    type: 'SELECT_QUOTE',
    quote: asset,
  }),
  updateTaskId: taskId => ({ type: 'SET_TASKID', taskId }),
  newPair: pair => ({ type: 'DISPLAY_NEW_PAIR', pair }),
};

export const getPairsAsync = {
  request: () => ({ type: 'GET_PAIRS_REQUEST' }),
  success: pairs => ({ type: 'GET_PAIRS_SUCCESS', pairs }),
  failure: error => ({ type: 'GET_PAIRS_FAILURE', error }),
};

export const getSinglePairAsync = {
  request: pair => ({ type: 'GET_SINGLE_PAIR_REQUEST', pair }),
  success: pair => ({ type: 'GET_SINGLE_PAIR_SUCCESS', pair }),
  failure: error => ({ type: 'GET_SINGLE_PAIR_FAILURE', error }),
};

export const getRLCAccountAsync = {
  request: () => ({ type: 'GET_RLC_ACCOUNT_REQUEST' }),
  success: account => ({ type: 'GET_RLC_ACCOUNT_SUCCESS', account }),
  failure: error => ({ type: 'GET_RLC_ACCOUNT_FAILURE', error }),
};

export const getRLCWalletAsync = {
  request: () => ({ type: 'GET_RLC_WALLET_REQUEST' }),
  success: amount => ({ type: 'GET_RLC_WALLET_SUCCESS', amount }),
  failure: error => ({ type: 'GET_RLC_WALLET_FAILURE', error }),
};

export const depositRLCAsync = {
  request: amount => ({ type: 'DEPOSIT_RLC_REQUEST', amount }),
  success: txHash => ({ type: 'DEPOSIT_RLC_SUCCESS', txHash }),
  failure: error => ({ type: 'DEPOSIT_RLC_FAILURE', error }),
};

export const withdrawRLCAsync = {
  request: amount => ({ type: 'WITHDRAW_RLC_REQUEST', amount }),
  success: txHash => ({ type: 'WITHDRAW_RLC_SUCCESS', txHash }),
  failure: error => ({ type: 'WITHDRAW_RLC_FAILURE', error }),
};

export const buyIExecTaskAsync = {
  request: ({ base, quote, precision }) => ({
    type: 'BUY_TASK_REQUEST',
    base,
    quote,
    precision,
  }),
  success: taskId => ({ type: 'BUY_TASK_SUCCESS', taskId }),
  failure: error => ({ type: 'BUY_TASK_FAILURE', error }),
};

export const watchTaskAsync = {
  request: taskId => ({ type: 'WATCH_TASK_REQUEST', taskId }),
  success: () => ({ type: 'WATCH_TASK_SUCCESS' }),
  failure: error => ({ type: 'WATCH_TASK_FAILURE', error }),
  abort: () => ({ type: 'WATCH_TASK_ABORT' }),
};

export const updateOracleAsync = {
  request: taskId => ({ type: 'UPDATE_ORACLE_REQUEST', taskId }),
  success: () => ({ type: 'UPDATE_ORACLE_SUCCESS' }),
  failure: error => ({ type: 'UPDATE_ORACLE_FAILURE', error }),
};

export const startup = () => ({ type: 'STARTUP' });

export const handleFormChange = formName => inputName => value => ({
  type: `SET_${formName}_FORM_${inputName}_FIELD`,
  value,
});
export const handleTransferFormChange = handleFormChange('TRANSFER');
