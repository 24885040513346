import React, { Component, Fragment } from 'react';
import Debug from 'debug';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FaCopy, FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import classNames from 'classnames';
import { ModalTitle, ModalPaper } from './utils';
import {
  set,
  depositRLCAsync,
  withdrawRLCAsync,
  handleTransferFormChange,
} from './actions';
import * as actions from './actions';
import { CHAIN_NAMES } from './config';
import Button from './Button';

const debug = Debug('AccountModal');

const stateToProps = state => ({
  hasInputError: state.transferForm.errors,
});
const Tooltip = connect(stateToProps)(
  ({ hasInputError, children, fieldName }) => {
    if (!!hasInputError[fieldName])
      return React.cloneElement(children, {
        error: true,
        className: 'hint--bottom',
        'aria-label': hasInputError[fieldName],
      });
    return children;
  },
);

const styles = theme => ({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  unsupported: {
    margin: '30px auto',
    textAlign: 'center',
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0 30px 0',
  },
  section: {
    flexDirection: 'column',
    display: 'flex',
    padding: 10,
    flexShrink: 0,
    flex: 1,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  justifyLeft: {
    alignItems: 'flex-start',
  },
  justifyRight: {
    alignItems: 'flex-end',
  },
  input: {
    border: 'none',
    color: theme.palette.common.black,
    backgroundColor: '#F5F5F5',
    padding: '0.3em 0.7em',
  },
  adornment: {
    color: theme.palette.common.black,
  },
  address: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  copyIcon: {
    margin: '0px 5px',
  },
  bottomSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  spacer: {
    flex: 1,
  },
  balance: {
    margin: '10px 0',
    fontSize: '1.2rem',
  },
  faucet: {
    textDecoration: 'underline',
  },
  selector: {
    borderRadius: 0,
    padding: '0.7rem',
    justifyContent: 'space-between',
    margin: 0,
    backgroundColor: '#F5F5F5',
    display: 'flex',
  },
  selected: {
    margin: 0,
    color: theme.palette.text.default,
    backgroundColor: theme.palette.primary.main,
  },
  titleSpacer: {
    height: '1rem',
  },
  transparent: {
    opacity: 0,
  },
  error: {
    color: '#f44336',
  },
});

const createHelpSentence = (direction, amount) => {
  if (direction === 'deposit')
    return `I want to make a ${amount} nRLC deposit from my wallet to my iExec account`;
  return `I want to make a ${amount} nRLC withdrawal from my iExec account to my wallet`;
};

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferDirection: 'deposit',
    };
  }
  changeTransferDirection(newDirection) {
    return this.setState({ transferDirection: newDirection });
  }
  render() {
    const {
      classes,
      showModal,
      toggleAccountModal,
      account,
      rlcWalletBalance,
      rlcAccountBalance,
      depositRLC,
      withdrawRLC,
      rlcToTransfer,
      handleRLCToTransferChange,
      hasInputError,
      chainId,
      notify,
    } = this.props;
    const { transferDirection } = this.state;
    const isSupportedChain = !!CHAIN_NAMES[chainId];
    const chainName = CHAIN_NAMES[chainId];
    const depositTooHigh = rlcWalletBalance.lt(rlcToTransfer)
      ? 'Wallet balance insufficient'
      : '';
    const withdrawTooHigh = rlcAccountBalance.stake.lt(rlcToTransfer)
      ? 'Account stake balance insufficient'
      : '';
    const transferTooHigh =
      transferDirection === 'deposit' ? depositTooHigh : withdrawTooHigh;
    const isTransferFormValid =
      !hasInputError.RLC_TO_TRANSFER && !transferTooHigh;
    const transferFormErrorMessage =
      (!!hasInputError.RLC_TO_TRANSFER && hasInputError.RLC_TO_TRANSFER) ||
      (!!transferTooHigh && transferTooHigh);
    debug('transferFormErrorMessage', transferFormErrorMessage);
    return (
      <Modal
        open={showModal}
        className={classes.modal}
        onClose={toggleAccountModal}
      >
        <ModalPaper>
          <ModalTitle title="Account" />
          <div
            aria-label="click to copy"
            onClick={e => {
              copy(account);
              notify({ message: 'copied to clipboard' });
            }}
            className={classNames(classes.address, 'hint--bottom')}
          >
            {account}
            <FaCopy className={classes.copyIcon} />
          </div>
          {!isSupportedChain && (
            <div className={classes.unsupported}>
              <p>Your current chain is not supported</p>
              <p>Use MetaMask to switch chain</p>
            </div>
          )}
          {isSupportedChain && (
            <Fragment>
              <div className={classes.main}>
                <div
                  className={classNames(classes.section, classes.justifyLeft)}
                >
                  <div className={classes.sectionTitle}>My Wallet</div>
                  <div className={classes.balance}>
                    {rlcWalletBalance.toString()} nRLC
                  </div>
                  <div className={classes.spacer} />
                  {isSupportedChain && (
                    <div>
                      <a
                        className={classes.faucet}
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://faucet.iex.ec/${chainName}/${account}`}
                      >
                        Get some RLC
                      </a>
                    </div>
                  )}
                </div>
                <div className={classes.section}>
                  <div className={classes.titleSpacer} />
                  <Tooltip fieldName="RLC_TO_TRANSFER">
                    <FormControl
                      required
                      error={!!hasInputError['RLC_TO_TRANSFER']}
                    >
                      <Input
                        value={rlcToTransfer.toString()}
                        onChange={handleRLCToTransferChange}
                        classes={{
                          root: classNames(
                            classes.input,
                            !isTransferFormValid && classes.error,
                          ),
                        }}
                        endAdornment={
                          <InputAdornment position="start">
                            <div
                              className={classNames(
                                classes.adornment,
                                !isTransferFormValid && classes.error,
                              )}
                            >
                              nRLC
                            </div>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Tooltip>
                  <Button
                    onClick={() => this.changeTransferDirection('deposit')}
                    className={classNames(
                      classes.selector,
                      classes.transferDirection,
                      transferDirection === 'deposit' && classes.selected,
                    )}
                  >
                    <FaAngleRight className={classes.transparent} />
                    <div>Deposit</div> <FaAngleRight />
                  </Button>
                  <Button
                    onClick={() => this.changeTransferDirection('withdraw')}
                    className={classNames(
                      classes.selector,
                      classes.transferDirection,
                      transferDirection === 'withdraw' && classes.selected,
                    )}
                  >
                    <FaAngleLeft />
                    <div>Withdraw</div>
                    <FaAngleLeft className={classes.transparent} />
                  </Button>
                </div>
                <div
                  className={classNames(classes.section, classes.justifyRight)}
                >
                  <div className={classes.sectionTitle}>iExec Account</div>
                  <div className={classes.balance}>
                    {rlcAccountBalance.stake
                      .add(rlcAccountBalance.locked)
                      .toString()}{' '}
                    nRLC
                  </div>
                  <div className={classes.spacer} />
                  <div>{rlcAccountBalance.locked.toString()} nRLC Locked</div>
                  <div>{rlcAccountBalance.stake.toString()} nRLC Staked</div>
                </div>
              </div>
              <div className={classes.bottomSection}>
                <div>
                  {createHelpSentence(
                    transferDirection,
                    rlcToTransfer.toString(),
                  )}
                </div>
                <Button
                  aria-label={transferFormErrorMessage}
                  onClick={() => {
                    if (!isTransferFormValid || !isSupportedChain) return;
                    transferDirection === 'deposit'
                      ? depositRLC(rlcToTransfer)
                      : withdrawRLC(rlcToTransfer);
                  }}
                  disabled={!isTransferFormValid || !isSupportedChain}
                  className={classNames(!isTransferFormValid && 'hint--bottom')}
                >
                  CONFIRM
                </Button>
              </div>
            </Fragment>
          )}
        </ModalPaper>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showModal: !!state.account && state.isAccountModalOpen,
  account: state.account,
  rlcWalletBalance: state.rlcWallet,
  rlcAccountBalance: state.rlcAccount,
  rlcToTransfer: state.transferForm.rlcToTransfer,
  hasInputError: state.transferForm.errors,
  chainId: state.chainId,
});

const mapDispatchToProps = dispatch => ({
  notify: params => dispatch(actions.notify(params)),
  toggleAccountModal: () => dispatch(set.toggleAccountModal()),
  depositRLC: amount => dispatch(depositRLCAsync.request(amount)),
  withdrawRLC: amount => dispatch(withdrawRLCAsync.request(amount)),
  handleRLCToTransferChange: event =>
    dispatch(handleTransferFormChange('RLC_TO_TRANSFER')(event.target.value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Account));
