export const DEFAULT_CHAIN = 5;

export const ORACLE_ADDRESSES = {
  5: '0x7Ca601977C9075bAe2F173bA248356280008AeaF',
};

export const APP_ADDRESSES = {
  5: '0xF661d6d8d701bd530c3019915f67d1bbB9bA3258',
};

export const DATASET_ADDRESSES = {
  5: '0xDA331E38817283B031Af8c25c3bA6948C05A345c',
};

export const HUB_ADDRESSES = {};

export const CHAIN_NAMES = {
  5: 'goerli',
};

export const BLOCKCHAIN_EXPLORERS = {
  5: 'https://goerli.etherscan.io',
};

export const DEFAULT_PROVIDERS = {
  5: 'goerli',
};

export const EXPLORER_BASE_URL = 'https://explorer.iex.ec/';

export const DEFAULT_PAIRS = [
  { base: 'btc', quote: 'eur', precision: 9 },
  { base: 'btc', quote: 'usd', precision: 9 },
  { base: 'eth', quote: 'btc', precision: 9 },
  { base: 'eth', quote: 'usd', precision: 9 },
  { base: 'eth', quote: 'eur', precision: 9 },
];
