import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import networkLogo from './images/network.svg';

const styles = theme => ({
  root: {
    'grid-area': 'contact',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: '1em',
    color: theme.palette.text.primary,
  },
  card: {
    padding: '2em',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${networkLogo})`,
    backgroundRepeat: 'repeat-y',
    minWidth: '275px',
    width: '650px',
    maxWidth: '80hv',
    '& p': {
      fontSize: '1em',
      lineHeight: '1.6em',
    },
    '& a': {
      color: theme.palette.text.link,
      textDecoration: 'none',
    },
  },
});

const ContactCard = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <p>
          If your DeFi smart contract needs a price feed that is simple to
          integrate with, secure and affordable, reach out at{' '}
          <a href="mailto:contact@iex.ec">contact@iex.ec</a>
        </p>
      </Card>
    </div>
  );
};

export default withTheme()(withStyles(styles)(ContactCard));
