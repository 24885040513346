import React from 'react';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Notifier from './Notifier';
import Header from './Header';
import Title from './Title';
import PriceTable from './PriceTable';
import ContactCard from './ContactCard';
import IntroCard from './IntroCard';
import Footer from './Footer';
import UpdateModal from './UpdateModal';
import AccountModal from './AccountModal';
import 'hint.css';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Overpass-Regular, sans-serif',
      'Montserrat-Regular, sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: 'Montserrat-Regular, sans-serif',
    },
  },
  palette: {
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
    secondary: {
      light: '#D4DCEA',
      main: '#A2B5D2',
      dark: '#4F5C73',
      contrastText: '#647799',
    },
    background: {
      default: '#F5F6FB',
      paper: '#fff',
      red: '#EF5353',
      green: '#11B15E',
      grey: '#6C6C6C',
      main: (opacity = '0.3') => `rgba(250,233,0,${opacity})`,
    },
    text: {
      primary: '#304058',
      secondary: '#304058',
      disabled: '#505050',
      link: '#3498db',
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true, // No more ripple, on the whole application 💣!
    },
    MuiInputLabel: {
      disableAnimation: true,
    },
  },
});

const styles = theme => ({
  appGrid: {
    width: '100%',
    display: 'grid',
    gridGap: '20px',
    gridTemplateRows: '5em 5em auto auto',
    gridTemplateAreas: `'header'
      'title'
      'intro'
      'prices'
      'contact'
      'footer'`,
    marginBottom: '3em',
  },
});

const App = withStyles(styles)(({ classes }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Notifier />
    <UpdateModal />
    <AccountModal />
    <div className={classes.appGrid}>
      <Header />
      <Title />
      <IntroCard />
      <PriceTable />
      <ContactCard />
      <Footer />
    </div>
  </MuiThemeProvider>
));

export default App;
