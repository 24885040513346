import Debug from 'debug';
import { combineReducers } from 'redux';
import { validateInt } from '../utils';

const debug = Debug('transferForm');

function errors(state = {}, action) {
  if (
    action.type.includes('SET_TRANSFER_FORM_') &&
    action.type.includes('_FIELD')
  ) {
    debug('action.type', action.type);
    const key = action.type.split('SET_TRANSFER_FORM_')[1].split('_FIELD')[0];
    debug('key', key);
    switch (action.type) {
      case 'SET_TRANSFER_FORM_RLC_TO_TRANSFER_FIELD':
        return { ...state, [key]: validateInt(action.value) };
      default:
        return state;
    }
  }
  return state;
}

function rlcToTransfer(state = '0', action) {
  switch (action.type) {
    case 'SET_TRANSFER_FORM_RLC_TO_TRANSFER_FIELD':
      if (!action.value) return '0';
      return action.value;
    case 'CLEAR_TRANSFER_FORM':
      return '0';
    default:
      return state;
  }
}

export default combineReducers({
  errors,
  rlcToTransfer,
});
