import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers/reducers';
import sagaWatchers from './sagas';
import { loadState, saveState } from './localStorage';
import './index.css';
import App from './App';

window.onbeforeunload = () =>
  saveState({
    pairsToDisplay: store.getState().pairsToDisplay,
  });

const sagaMiddleware = createSagaMiddleware();
const history = createHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  reducers(history),
  loadState(),
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware(history)),
  ),
);
sagaMiddleware.run(sagaWatchers);

store.subscribe(() => {});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
