import React, { Fragment, useEffect } from 'react';
import Debug from 'debug';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import {
  set,
  buyIExecTaskAsync,
  updateOracleAsync,
  getSinglePairAsync,
  notify,
} from './actions';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from './Button';
import { CHAIN_NAMES } from './config';
import { isBytes32 } from './utils';

const debug = Debug('UpdateModal');

const styles = theme => ({
  paper: {
    width: '500px',
    maxWidth: '100vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    '& :first-child': {
      marginLeft: '0',
    },
    '& :last-child': {
      marginRight: '0',
    },
  },
  select: {
    flex: 1,
    margin: '0 5px',
  },
  cancel: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.background.black,
  },
  textField: {
    margin: '10px 0',
    '& input': {
      paddingTop: '9.5px',
      paddingBottom: '9.5px',
    },
  },
  progress: {
    marginLeft: '1em',
    color: theme.palette.common.black,
  },
});

const UpdateModal = ({
  classes,
  chainId,
  isLoggedIn,
  showModal,
  showAddButton,
  selectTab,
  activeTab,
  listedBases,
  listedQuotes,
  listedPairs,
  selectedBase,
  selectedQuote,
  updateTaskId,
  selectBase,
  selectQuote,
  setTaskId,
  buyTask,
  addPair,
  updateOracle,
  close,
  isPendingUpdate,
  isPendingDeal,
  isPendingTask,
}) => {
  useEffect(() => {
    debug('close on disconnect or invalid chain');
    if (!isLoggedIn || !CHAIN_NAMES[chainId]) close();
  }, [close, isLoggedIn, chainId]);

  const selectedPaire =
    !!selectedBase &&
    !!selectedQuote &&
    listedPairs.find(
      p => selectedBase.value === p.base && selectedQuote.value === p.quote,
    );

  const isValidPair = !!selectedPaire;

  const handleFetchPrice = () => {
    if (isValidPair && !isPendingDeal) buyTask(selectedPaire);
  };

  const isValidTaskId = updateTaskId === '' ? true : isBytes32(updateTaskId);

  const isValidUpdateOracle = !!updateTaskId && isValidTaskId;

  const handleUpdateOracle = () => {
    if (isValidUpdateOracle) updateOracle(updateTaskId);
  };

  const handleAddPaire = () => {
    if (isValidPair && !isPendingDeal) addPair(selectedPaire);
  };

  return (
    <Modal open={showModal}>
      <div className={classes.paper}>
        {isLoggedIn && (
          <AppBar position="static" color="default">
            <Tabs value={activeTab} onChange={selectTab} variant="fullWidth">
              <Tab label="Run iExec Workers" />
              <Tab label="Update the Oracle" />
            </Tabs>
          </AppBar>
        )}
        <div className={classes.content}>
          {((isLoggedIn && activeTab === 0) || !isLoggedIn) && (
            <Fragment>
              <FormControl>
                <div className={classes.selectContainer}>
                  <Select
                    className={classes.select}
                    isDisabled={isPendingDeal}
                    options={listedBases}
                    placeholder="Base"
                    value={selectedBase}
                    onChange={selectBase}
                  />
                  <Select
                    className={classes.select}
                    isDisabled={isPendingDeal}
                    options={listedQuotes}
                    placeholder="Quote"
                    value={selectedQuote}
                    onChange={selectQuote}
                  />
                </div>
                {showAddButton && (
                  <Button
                    aria-label={!isValidPair && 'this pair is not available'}
                    className={!isValidPair && 'hint--bottom'}
                    disabled={!isValidPair || isPendingDeal}
                    onClick={handleAddPaire}
                  >
                    Display pair
                  </Button>
                )}
                {isLoggedIn && (
                  <Button
                    aria-label={!isValidPair && 'this pair is not available'}
                    className={!isValidPair && 'hint--bottom'}
                    disabled={!isValidPair || isPendingDeal}
                    onClick={handleFetchPrice}
                  >
                    {!isPendingDeal && 'Fetch the price'}
                    {isPendingDeal && (
                      <Fragment>
                        Waiting for deal
                        <CircularProgress
                          className={classes.progress}
                          size={'1em'}
                        />
                      </Fragment>
                    )}
                  </Button>
                )}
              </FormControl>
            </Fragment>
          )}
          {isLoggedIn && activeTab === 1 && (
            <Fragment>
              <FormControl variant="outlined">
                <TextField
                  disabled={isPendingUpdate}
                  className={classes.textField}
                  placeholder="task Id"
                  defaultValue={updateTaskId}
                  onChange={setTaskId}
                  error={!isValidTaskId}
                  margin="none"
                  variant="outlined"
                />
                <Button
                  disabled={
                    !isValidUpdateOracle || isPendingTask || isPendingUpdate
                  }
                  onClick={handleUpdateOracle}
                >
                  {!isPendingTask && !isPendingUpdate && 'Update the oracle'}
                  {isPendingTask && (
                    <Fragment>
                      Waiting for task result
                      <CircularProgress
                        className={classes.progress}
                        size={'1em'}
                      />
                    </Fragment>
                  )}
                  {isPendingUpdate && (
                    <Fragment>
                      Waiting for update
                      <CircularProgress
                        className={classes.progress}
                        size={'1em'}
                      />
                    </Fragment>
                  )}
                </Button>
              </FormControl>
            </Fragment>
          )}
          <FormControl>
            <Button className={classes.cancel} onClick={close}>
              Cancel
            </Button>
          </FormControl>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: !!state.account,
  chainId: state.chainId,
  showModal: state.updateModalDisplay.isOpen,
  showAddButton: state.updateModalDisplay.showAddButton,
  activeTab: state.modalActiveTab,
  listedBases: state.listedBases,
  listedQuotes: state.listedQuotes,
  listedPairs: state.listedPairs,
  selectedBase: state.selectedBase,
  selectedQuote: state.selectedQuote,
  updateTaskId: state.updateTaskId,
  isPendingDeal: state.isLoading['BUY_TASK'],
  isPendingTask: state.isLoading['WATCH_TASK'],
  isPendingUpdate: state.isLoading['UPDATE_ORACLE'],
});

const mapDispatchToProps = dispatch => ({
  notify: params => dispatch(notify(params)),
  selectTab: (event, value) => dispatch(set.modalActiveTab(value)),
  selectBase: ({ value, label }) => dispatch(set.base({ value, label })),
  selectQuote: ({ value, label }) => dispatch(set.quote({ value, label })),
  setTaskId: event => dispatch(set.updateTaskId(event.target.value)),
  close: () => dispatch(set.updateModal.close()),
  buyTask: ({ base, quote, precision }) => {
    dispatch(set.newPair({ base, quote, precision }));
    dispatch(buyIExecTaskAsync.request({ base, quote, precision }));
  },
  updateOracle: taskId => {
    dispatch(updateOracleAsync.request(taskId));
  },
  addPair: pair => {
    dispatch(set.newPair(pair));
    dispatch(set.updateModal.close());
    dispatch(getSinglePairAsync.request(pair));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(UpdateModal));
