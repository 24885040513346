import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import rlcLogo from './images/rlc.svg';
import { CHAIN_NAMES } from './config';

export const ChainName = connect(state => ({
  chainId: state.chainId,
}))(
  withStyles(theme => ({}))(({ classes, chainId }) => (
    <div aria-label="Use Metamask to switch chain" className="hint--bottom">
      {CHAIN_NAMES[chainId] || `chain ${chainId}`}
    </div>
  )),
);

const RLCLogoStyles = theme => ({
  rlcLogo: {
    height: 'auto',
    width: '2em',
  },
});

export const RLCLogo = withStyles(RLCLogoStyles)(({ classes }) => (
  <img className={classes.rlcLogo} src={rlcLogo} alt="iExec RLC logo" />
));

export const ModalTitle = withStyles(theme => ({
  topSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
  },
  accountTitle: {
    fontSize: '2rem',
    margin: '0 10px',
  },
}))(({ classes, title }) => (
  <div className={classes.topSection}>
    <RLCLogo />
    <div className={classes.title}>
      <div className={classes.accountTitle}>{title}</div> <ChainName />
    </div>
  </div>
));

export const ModalPaper = withStyles(theme => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 'fit-content',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 3,
    color: theme.palette.common.black,
    '& a': {
      color: theme.palette.common.black,
    },
  },
}))(({ classes, children }) => (
  <div className={classes.paper} tabIndex={-1}>
    {children}
  </div>
));

const isInt = value => {
  if (/^(-|\+)?([0-9]+|Infinity)$/.test(value)) return true;
  return false;
};

export const isBytes32 = value => {
  if (/^(0x)([0-9a-f]{2}){32}$/.test(value)) return true;
  return false;
};

export const validateInt = str => {
  if (!str) return '';
  if (!isInt(str)) return 'must be an integer';
  if (!(Number(str) >= 0)) return 'must be positive';
  return '';
};
