import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import iexecLogo from './images/iexec-logo.svg';
import { set, startup } from './actions';
import AccountButton from './AccountButton';

const styles = theme => ({
  root: {
    'grid-area': 'header',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  leftBox: {
    display: 'flex',
    '& a': {
      textDecoration: 'none',
      color: 'black',
    },
  },
  logoBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '1em',
  },
  logo: {
    height: 'auto',
    width: '6em',
  },
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    margin: '0px 10px',
    marginBottom: '0.2em',
  },
  rightBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  docText: {
    fontSize: '1.3em',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
});

class Header extends Component {
  componentDidMount() {
    this.props.fetchData();
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.leftBox}>
          <a href="https://iex.ec/decentralized-oracles/">
            <div className={classes.logoBox}>
              <img className={classes.logo} src={iexecLogo} alt="iExec logo" />
              <div className={classes.title}>Decentralized Oracle</div>
            </div>
          </a>
        </div>
        <div className={classes.rightBox}>
          <AccountButton />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setAccount: account => dispatch(set.account(account)),
  setChainID: chainId => dispatch(set.chainId(chainId)),
  fetchData: () => dispatch(startup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme()(withStyles(styles)(Header)));
