import React, { Component } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';

const debug = Debug('Notifier');

class Notifier extends Component {
  constructor(props) {
    super(props);
    this._notificationSystem = null;
  }
  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }
  componentDidUpdate() {
    debug('componentDidUpdate', this.props);
    const { notification } = this.props;

    if (notification.title || notification.message) {
      this._notificationSystem.addNotification(notification);
    }
  }
  render() {
    return (
      <div>
        <NotificationSystem ref="notificationSystem" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notification: state.notification,
});

export default connect(mapStateToProps)(Notifier);
